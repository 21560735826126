import { useMemo } from 'react';

import isEmpty from 'lodash/isEmpty';

import type { Package } from '@/infra/types/voyageInfo/package';

import CabinPrice from '@/components/CabinCard/CabinPrice';
import UIResource from '@/components/UIResource';
import UIResourceHtml from '@/components/UIResource/Html';
import Uplift from '@/components/Uplift';
import { CABIN_CATEGORY_CODES } from '@/constants/cabinCategoryCodes';
import { selectCabins, selectPriceType, selectSailors } from '@/ducks/filters/selectors';
import { selectIsReady } from '@/ducks/uplift';
import { getAmountPerItem } from '@/helpers/data/mappers/Summary';
import { useAppSelector } from '@/store';

import './PackagePrice.scss';
type Props = {
  customUpliftIcon?: React.ReactNode;
  noUplift?: boolean;
  packageData?: Pick<Package, 'bestPriceSailing' | 'duration' | 'sailingList' | 'startingPrice'>;
  strikethroughValue?: number;
};

const priceFootnote = {
  SailorPerNight: 'Package.average.perSailorPerNight.text',
  perCabin: 'Package.average.perCabin.text',
  perSailor: 'Package.average.perSailor.text',
} as const;

const PackagePrice = ({ customUpliftIcon, noUplift, packageData, strikethroughValue }: Props) => {
  const cabins = useAppSelector(selectCabins);
  const priceType = useAppSelector(selectPriceType);
  const sailorCount = useAppSelector(selectSailors);

  const isUpliftReady = useAppSelector(selectIsReady);

  const { startingPrice } = packageData?.bestPriceSailing ?? {};
  const sailingPrice = getAmountPerItem(startingPrice, { cabins, priceType, sailors: sailorCount });
  const sailing = packageData?.sailingList?.at(-1);
  const withUplift = !noUplift && isUpliftReady && sailing && !isEmpty(sailing.ports) && !isEmpty(sailing.startDate);
  const upliftIcon = withUplift && (
    <span className="packageCardupliftTooltip" id="upliftToolTipPackageCard">
      <span className="packageCardupliftTooltipIcon">i</span>
    </span>
  );
  const leadCabin = packageData?.sailingList?.at(0)?.cabinCategoryName;
  const leadCabinCode = packageData?.sailingList?.at(0)?.cabinCategoryCode;
  const upliftSkeletonProps = useMemo(
    () => ({
      height: '18px',
      width: '150px',
    }),
    [],
  );
  const isSuite =
    leadCabinCode && [CABIN_CATEGORY_CODES.MEGA_ROCKSTAR, CABIN_CATEGORY_CODES.ROCKSTAR_SUITES].includes(leadCabinCode);

  return (
    <div className="PackagePrice">
      <div className="fromLabel">
        {leadCabin ? (
          <UIResourceHtml
            id={isSuite ? 'PackageCard.leadInPrice.suite' : 'PackageCard.leadInPrice.cabin'}
            values={{ Metacategory: leadCabin }}
          />
        ) : (
          <UIResource id="Cabins.Lead.Price.label.from" />
        )}
      </div>
      <div className="priceTotal">
        <CabinPrice
          abTestPriceType={priceType}
          currencyCode={startingPrice?.currencyCode ?? 'USD'}
          duration={packageData?.duration}
          isForceLineBreak={false}
          sailors={sailorCount}
          startingPrice={startingPrice}
          strikethroughValue={strikethroughValue}
        />
      </div>
      {priceType && (
        <div className="priceFootNote">
          <UIResource id={priceFootnote[priceType]} />
        </div>
      )}
      {withUplift && (
        <Uplift
          currencyCode={startingPrice?.currencyCode ?? 'USD'}
          customIcon={customUpliftIcon ? upliftIcon : ''}
          isHorizontal
          price={sailingPrice}
          priceType={priceType}
          sailing={sailing}
          showOrFromLabel
          skeletonProps={upliftSkeletonProps}
        />
      )}
    </div>
  );
};

export default PackagePrice;
